import React, { Component } from "react";
import "./Registration.scss";
import RegistrationForm from "./registrationForm/RegistrationForm";
import RegistrationHeader from "./RegistrationHeader";

export default class Registration extends Component {
    render() {
        return (
            <div className="registration">
                <RegistrationHeader />
                <div className="registrationBox" style={{ marginTop: 50 }}>
                    <RegistrationForm />
                </div>
            </div>
        );
    }
}
