import React from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { Route, Router, Switch } from "react-router-dom";
import Header from "./components/layout/header/Header";
import Content from "./components/layout/content/Content";
import { history } from "./helpers/history";
import { library } from "@fortawesome/fontawesome-svg-core";
import "./App.scss";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import Registration from "./components/pages/registration/Registration";

library.add(faUndo);

function App() {
    return (
        <Router history={history}>
            <Switch>
                <Route path="/registration">
                    <Registration />
                </Route>
                <Route path="/">
                    <Header />
                    <Content />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
