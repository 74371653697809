import { Button, CheckBox } from "devextreme-react";
import React, { Component } from "react";
import { getAllModules } from "../../../actions/moduleActions";
import store from "../../../store";

export default class ModuleSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: [],
            selectedModules: [],
        };
    }

    componentDidMount() {
        store.dispatch(getAllModules()).then((response) => this.setState({ modules: response }));
    }

    handleUpdate(e, module) {
        if (e.value) {
            this.setState({ selectedModules: [...this.state.selectedModules, { Module: module }] });
        } else {
            this.setState({ selectedModules: this.state.selectedModules.filter((m) => m.Module !== module) });
        }
    }

    render() {
        return (
            <div>
                {this.state.modules.map((module) => {
                    return (
                        <div>
                            {
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "80%",
                                        marginTop: 20,
                                    }}
                                >
                                    <span>{module.name + "   " + module.price + "€"}</span>{" "}
                                    <span
                                        style={{
                                            alignItems: "flex-end",
                                            justifyContent: "flex-end",
                                            alignSelf: "flex-end",
                                        }}
                                    >
                                        <CheckBox onValueChanged={(e) => this.handleUpdate(e, module)} />
                                    </span>
                                </div>
                            }
                        </div>
                    );
                })}
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 30 }}>
                    <Button
                        style={{ height: 40, marginRight: 10 }}
                        disabled={this.state.step === 1}
                        text="Zurück"
                        onClick={this.props.handlePreviousStep}
                    />
                    <Button
                        text="Weiter"
                        style={{ marginLeft: 10, width: 120 }}
                        type="default"
                        onClick={(e) => {
                            e.validationGroup = "Modules";
                            this.props.updateModules(this.state.selectedModules);
                            this.props.handleNextStep(e);
                        }}
                        useSubmitBehavior={true}
                    />
                </div>
            </div>
        );
    }
}
