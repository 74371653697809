import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Container extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ maxWidth: "1000px", display: "flex", height: 40 }}>
                <div style={{width: 500, borderStyle: "solid", borderWidth: 1, display: "flex", justifyContent: "center", flexDirection: "column", paddingLeft: 10}}>
                    <a rel="noreferrer noopener" target="_blank" href={"https://" + ((this.props.container.customDomain != null && this.props.container.customDomain !== "") ? this.props.container.customDomain : this.props.container.frontendUrl)}>
                    https://{(this.props.container.customDomain != null && this.props.container.customDomain !== "") ? this.props.container.customDomain : this.props.container.frontendUrl}
                    </a>
                </div>
                <div style={{width: 300, borderStyle: "solid", borderWidth: 1, display: "flex", justifyContent: "center", flexDirection: "column", paddingLeft: 10}}>
                    {this.props.container.License ? 
                    <Link to={"/lizenzen/" + this.props.container.License.LicenseId}>
                        {this.props.container.License.StoreName}
                    </Link> 
                    : "frei"}</div>
                <div style={{width: 200, borderStyle: "solid", borderWidth: 1, display: "flex", justifyContent: "center", flexDirection: "column", paddingLeft: 10}}>{this.props.container.deployStatusString}</div>
            </div>
        );
    }
}
