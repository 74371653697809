class Config {
    constructor() {
        this.backendHost = "/b";
        this.currencyFormat = "0[.]00 $";
        this.grammagesFormat = "0[.]00";
        this.imageStorageBlobURL = "https://ggbiosmedia.blob.core.windows.net/ggbiosmedia/";
    }
}

export default Config;
