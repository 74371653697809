import { Button, LoadIndicator, SelectBox, TextBox, ValidationSummary } from "devextreme-react";
import { CompareRule, CustomRule, EmailRule, RequiredRule, Validator } from "devextreme-react/validator";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import {
    checkCompanyKey,
    checkPayment,
    checkStoreName,
    deployWithoutToken,
    getPaymentLink,
    registerNewLicense,
    setupBios,
} from "../../../../actions/licensesActions";
import { sendVerificationMail } from "../../../../actions/userActions";
import store from "../../../../store";
import Confirmation from "../../confirmation/Confirmation";
import ModuleSelection from "../ModuleSelection";

class RegistrationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            paymentFailed: false,
            showPaymentForm: false,
            payedByParent: false,
            showErrorMessage: false,
            storeNameUnavailable: false,
            loading: false,
            verificationCode: "",
            verificationCodeCheck: "",
            passwordConfirmation: "",
            allowSendCode: true,
            license: {
                email: "",
                password: "",
                ownerFirstName: "",
                ownerLastName: "",
                City: "",
                Street: "",
                zip: "",
                AddressDetails: "",
                houseNumber: "",
                companyKey: "",
                paymentConfirmed: "false",
                title: "",
                StoreName: "",
                ModulesBought: [],
            },
        };

        this.handleNextStep = this.handleNextStep.bind(this);
        this.passwordComparison = this.passwordComparison.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.registerLicense = this.registerLicense.bind(this);
        this.handleDeployForPaidLicense = this.handleDeployForPaidLicense.bind(this);
        this.handlePreviousStep = this.handlePreviousStep.bind(this);
    }

    componentDidMount() {
        let _t = Math.round(new Date().getTime() / 1000);
        let novalnetSDKUrl = "https://paygate.novalnet.de/v2/checkout.js?t=" + _t;
        const script = document.createElement("script");
        script.src = novalnetSDKUrl;
        script.async = true;
        script.id = "novalnet-checkout-js";
        document.body.appendChild(script);
        script.addEventListener("load", this.handleScriptLoaded);
        script.addEventListener("error", this.handleScriptLoadError);
        window.React = React;
        window.ReactDOM = ReactDOM;
        let params = new URLSearchParams(window.location.search);
        var license = JSON.parse(localStorage.getItem("license"));
        var step = JSON.parse(localStorage.getItem("step"));
        if (license) this.setState({ license: license, step: step ? step : 1 });
        if (params.get("tid") != null && license && params.get("status") !== "FAILURE") {
            store.dispatch(registerNewLicense(license)).then((response) => {
                store
                    .dispatch(
                        checkPayment(
                            params.get("tid"),
                            response.LicenseId,
                            license.ownerFirstName,
                            license.ownerLastName,
                            license.email,
                            license.password
                        )
                    )
                    .then((response) => {
                        if (params.get("status") === "FAILURE") {
                            this.setState({ paymentFailed: true });
                        } else if (response.isPaidFor) {
                            localStorage.removeItem("license");
                            this.setState({ paymentConfirmed: true });
                        }
                    });
            });
        }
    }

    togglePaymentForm() {
        this.setState({ showPaymentForm: !this.state.showPaymentForm });
    }

    componentWillUnmount() {
        let element = document.getElementById("novalnet-checkout-js");
        let elementParent = document.getElementById("novalnet-checkout-js").parentNode;
        elementParent.removeChild(element);
    }

    handleChange(e, fieldName = null) {
        var { name } = e.event ? e.event.target : "";
        if (!name) name = fieldName;
        const { value } = e;
        const { license } = this.state;
        this.setState({
            license: {
                ...license,
                [name]: value,
            },
        });
    }

    passwordComparison() {
        return this.state.license.password;
    }

    handleNextStep(e) {
        localStorage.setItem("license", JSON.stringify(this.state.license));
        let result = { isValid: false };
        if (e.validationGroup === "Modules") result.isValid = this.state.license.ModulesBought.length > 0;
        else result = e.validationGroup.validate();
        if (this.state.paymentFailed) this.setState({ paymentFailed: false });
        if (result.isValid) {
            this.setState({ step: this.state.step + 1, loading: false }, () =>
                localStorage.setItem("step", JSON.stringify(this.state.step))
            );
        }
    }

    handlePreviousStep() {
        this.setState({ step: this.state.step - 1 }, () =>
            localStorage.setItem("step", JSON.stringify(this.state.step))
        );
    }

    registerLicense() {
        store.dispatch(registerNewLicense(this.state.license));
    }

    handleDeployForPaidLicense() {
        const { license } = this.state;
        store.dispatch(registerNewLicense(license)).then((response) => {
            store
                .dispatch(
                    checkPayment(
                        "",
                        response.LicenseId,
                        license.ownerFirstName,
                        license.ownerLastName,
                        license.email,
                        license.password
                    )
                )
                .then((response) => {
                    if (response.isPaidFor) {
                        this.setState({ paymentConfirmed: true, payedByParent: true, loading: false });
                        store.dispatch(deployWithoutToken(response.LicenseNumber)).then((deployResponse) => {
                            if (deployResponse.status === 200)
                                store.dispatch(
                                    setupBios(
                                        response.LicenseNumber,
                                        this.state.license.ownerFirstName,
                                        this.state.license.ownerLastName,
                                        this.state.license.email,
                                        this.state.license.password
                                    ),
                                    () => localStorage.removeItem("license")
                                );
                        });
                    }
                });
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && (
                    <div className={"dx-field"}>
                        <div className={"dx-field-value"}>
                            <LoadIndicator visible={this.state.loading} />
                        </div>
                    </div>
                )}

                {!this.state.loading && (
                    <React.Fragment>
                        {this.state.paymentFailed && !this.state.showPaymentForm && (
                            <React.Fragment>
                                <h2>Abonnieren fehlgeschlagen</h2>
                                <div>
                                    <p>
                                        Ihre Zahlung war nicht erfolgreich, bitte versuchen Sie es erneut oder wenden
                                        sie sich an den Support
                                    </p>
                                </div>
                            </React.Fragment>
                        )}
                        {!this.state.paymentConfirmed && !this.state.showPaymentForm && (
                            <form
                                onSubmit={this.handleSubmit}
                                onKeyPress={(e) => {
                                    if (e.keyCode === 13) {
                                        this.handleNextStep();
                                    }
                                }}
                            >
                                <h5>{"Schritt " + this.state.step + " von 6"}</h5>

                                {this.state.step === 1 && (
                                    <React.Fragment>
                                        <h4>Bitte gib deine E-Mail Adresse an</h4>

                                        <div className={"dx-field"}>
                                            <TextBox
                                                name="email"
                                                mode="email"
                                                placeholder="E-Mail-Adresse"
                                                value={this.state.license.email}
                                                email
                                                valueChangeEvent="input"
                                                onValueChanged={this.handleChange}
                                            >
                                                <Validator validationGroup="Email">
                                                    <EmailRule
                                                        ignoreEmptyValue={false}
                                                        message={"Ungültige E-Mail Addresse"}
                                                    />
                                                    <RequiredRule message="Dieses Feld wird benötigt" />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button
                                                disabled={!this.state.allowSendCode}
                                                text={
                                                    this.state.verificationCodeCheck === ""
                                                        ? "Code senden"
                                                        : "Code erneut senden"
                                                }
                                                style={{ marginBottom: 10 }}
                                                type="default"
                                                validationGroup="Email"
                                                onClick={(e) => {
                                                    if (e.validationGroup.validate().isValid) {
                                                        this.setState({ allowSendCode: false });
                                                        store
                                                            .dispatch(sendVerificationMail(this.state.license.email))
                                                            .then((response) => {
                                                                this.setState({ verificationCodeCheck: response });
                                                            });
                                                        setTimeout(() => this.setState({ allowSendCode: true }), 3000);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className={"dx-field"}>
                                            <TextBox
                                                name="code"
                                                mode="text"
                                                placeholder="Verifizierungscode eingeben"
                                                valueChangeEvent="input"
                                                value={this.state.verificationCode}
                                                style={{ height: 40 }}
                                                onValueChanged={(e) => {
                                                    this.setState({ verificationCode: e.value });
                                                }}
                                            >
                                                <Validator validationGroup="Code">
                                                    <CustomRule
                                                        message={"Code ist nicht korrekt"}
                                                        validationCallback={() =>
                                                            this.state.verificationCode ===
                                                            this.state.verificationCodeCheck.toString()
                                                        }
                                                    />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        <div className={"dx-field"}>
                                            <div className={"dx-field-value"}>
                                                <ValidationSummary id="summary"></ValidationSummary>

                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                        style={{ height: 40, marginRight: 10 }}
                                                        disabled={this.state.step === 1}
                                                        text="Zurück"
                                                        onClick={this.handlePreviousStep}
                                                    />

                                                    <Button
                                                        disabled={
                                                            !(
                                                                this.state.verificationCodeCheck.toString() ===
                                                                    this.state.verificationCode &&
                                                                this.state.verificationCodeCheck !== ""
                                                            )
                                                        }
                                                        style={{ width: 130 }}
                                                        text="Weiter"
                                                        type="default"
                                                        validationGroup="Email"
                                                        onClick={this.handleNextStep}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}

                                {this.state.step === 2 && (
                                    <React.Fragment>
                                        <h4>Lege ein Passwort fest</h4>
                                        <div className={"dx-field"}>
                                            <TextBox
                                                name="password"
                                                mode="password"
                                                valueChangeEvent="input"
                                                placeholder="Passwort"
                                                value={this.state.license.password}
                                                required
                                                onValueChanged={this.handleChange}
                                            >
                                                <Validator validationGroup="Password">
                                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                                    <CustomRule
                                                        message={
                                                            "Passwort muss mindestens 6 Zeichen lang sein, einen Groß- und einen Kleinbuchstaben, sowie eine Ziffer und ein Sonderzeichen enthalten"
                                                        }
                                                        validationCallback={(e) => {
                                                            return new RegExp(
                                                                /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{6,}$/
                                                            ).test(e.value);
                                                        }}
                                                    />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        <div className={"dx-field"}>
                                            <TextBox
                                                name="password"
                                                mode="password"
                                                valueChangeEvent="input"
                                                placeholder="Passwort wiederholen"
                                                value={this.state.passwordConfirmation}
                                                onValueChanged={(e) => this.setState({ passwordConfirmation: e.value })}
                                                required
                                            >
                                                <Validator validationGroup="Password">
                                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                                    <CompareRule
                                                        message="Password und Wiederholung stimmen nicht überein"
                                                        comparisonTarget={this.passwordComparison}
                                                    />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        <div className={"dx-field"}>
                                            <div className={"dx-field-value"}>
                                                <ValidationSummary id="summary"></ValidationSummary>
                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                        style={{ height: 40, marginRight: 10 }}
                                                        disabled={this.state.step === 1}
                                                        text="Zurück"
                                                        onClick={() => {
                                                            this.handlePreviousStep();
                                                            this.setState({
                                                                verificationCodeCheck: "",
                                                                verificationCode: "",
                                                            });
                                                        }}
                                                    />
                                                    <Button
                                                        text="Weiter"
                                                        type="default"
                                                        style={{ marginLeft: 10, width: 120 }}
                                                        validationGroup="Password"
                                                        onClick={this.handleNextStep}
                                                        useSubmitBehavior={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                                {this.state.step === 3 && (
                                    <React.Fragment>
                                        <h4>Gib deine persönlichen Daten an</h4>
                                        <div className={"dx-field"}>
                                            <SelectBox
                                                name="title"
                                                type="default"
                                                placeholder="Anrede"
                                                value={this.state.license.title}
                                                required
                                                items={["Herr", "Frau"]}
                                                onValueChanged={(e) => this.handleChange(e, "title")}
                                            >
                                                <Validator validationGroup="UserData">
                                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                        <div className={"dx-field"}>
                                            <TextBox
                                                name="ownerFirstName"
                                                type="default"
                                                placeholder="Vorname"
                                                valueChangeEvent="input"
                                                value={this.state.license.ownerFirstName}
                                                required
                                                onValueChanged={this.handleChange}
                                            >
                                                <Validator validationGroup="UserData">
                                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        <div className={"dx-field"}>
                                            <TextBox
                                                name="ownerLastName"
                                                type="default"
                                                placeholder="Nachname"
                                                value={this.state.license.ownerLastName}
                                                required
                                                onValueChanged={this.handleChange}
                                            >
                                                <Validator validationGroup="UserData">
                                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        <div className={"dx-field"}>
                                            <TextBox
                                                name="Street"
                                                type="default"
                                                placeholder="Straße"
                                                valueChangeEvent="input"
                                                value={this.state.license.Street}
                                                required
                                                onValueChanged={this.handleChange}
                                            >
                                                <Validator validationGroup="UserData">
                                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        <div className={"dx-field"}>
                                            <TextBox
                                                name="houseNumber"
                                                type="default"
                                                placeholder="Hausnummer"
                                                valueChangeEvent="input"
                                                value={this.state.license.houseNumber}
                                                required
                                                onValueChanged={this.handleChange}
                                            >
                                                <Validator validationGroup="UserData">
                                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        <div className={"dx-field"}>
                                            <TextBox
                                                name="Zip"
                                                type="default"
                                                placeholder="Postleitzahl"
                                                valueChangeEvent="input"
                                                value={this.state.license.Zip}
                                                required
                                                onValueChanged={this.handleChange}
                                            >
                                                <Validator validationGroup="UserData">
                                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        <div className={"dx-field"}>
                                            <TextBox
                                                name="City"
                                                type="default"
                                                placeholder="Stadt"
                                                valueChangeEvent="input"
                                                value={this.state.license.City}
                                                required
                                                onValueChanged={this.handleChange}
                                            >
                                                <Validator validationGroup="UserData">
                                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        <div className={"dx-field"}>
                                            <TextBox
                                                name="AddressDetails"
                                                type="default"
                                                placeholder="Addresszusatz"
                                                valueChangeEvent="input"
                                                value={this.state.license.AddressDetails}
                                                required
                                                onValueChanged={this.handleChange}
                                            />
                                        </div>
                                        <div className={"dx-field"}>
                                            <div className={"dx-field-value"}>
                                                <ValidationSummary id="summary"></ValidationSummary>
                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                        style={{ height: 40, marginRight: 10 }}
                                                        disabled={this.state.step === 1}
                                                        text="Zurück"
                                                        onClick={this.handlePreviousStep}
                                                    />
                                                    <Button
                                                        text="Weiter"
                                                        style={{ marginLeft: 10, width: 120 }}
                                                        type="default"
                                                        validationGroup="UserData"
                                                        onClick={this.handleNextStep}
                                                        useSubmitBehavior={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                                {this.state.step === 4 && (
                                    <React.Fragment>
                                        <h4>Daten des Geschäfts</h4>
                                        <p>
                                            Gehört dein Geschäft zu einem Unternehmen das BIOS bereits nutzt, trage
                                            bitte den Schlüssel des entsprechenden Unternehmens ein. Ist dies nicht der
                                            Fall lass das Feld leer und es wird ein neues Unternehmen für dich angelegt.
                                        </p>
                                        <div className={"dx-field"}>
                                            <TextBox
                                                name="companyKey"
                                                type="default"
                                                placeholder="Unternehmensschlüssel"
                                                valueChangeEvent="input"
                                                value={this.state.license.companyKey}
                                                required
                                                onValueChanged={this.handleChange}
                                            ></TextBox>
                                        </div>
                                        {this.state.showErrorMessage && (
                                            <p style={{ color: "red" }}>
                                                Zum angebenen Unternehmenschlüssel wurde keine Lizenz gefunden, bitte
                                                prüfe ob du den Code korrekt eingegeben hast
                                            </p>
                                        )}
                                        <div className={"dx-field"}>
                                            <TextBox
                                                name="StoreName"
                                                type="default"
                                                placeholder="Dein Geschäftsname"
                                                valueChangeEvent="input"
                                                value={this.state.license.StoreName}
                                                required
                                                onValueChanged={this.handleChange}
                                            >
                                                <Validator validationGroup="UserData">
                                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        {this.state.storeNameUnavailable && (
                                            <p style={{ color: "red" }}>
                                                Der angegebene Geschäftsname ist bereits vergeben
                                            </p>
                                        )}
                                        <div className={"dx-field"}>
                                            <div className={"dx-field-value"}>
                                                <ValidationSummary id="summary"></ValidationSummary>
                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                        style={{ height: 40, marginRight: 10 }}
                                                        disabled={this.state.step === 1}
                                                        text="Zurück"
                                                        onClick={this.handlePreviousStep}
                                                    />
                                                    <Button
                                                        text="Weiter"
                                                        type="default"
                                                        style={{ marginLeft: 10, width: 120 }}
                                                        validationGroup="UserData"
                                                        onClick={(e) => {
                                                            this.setState({
                                                                showErrorMessage: false,
                                                                storeNameUnavailable: false,
                                                            });
                                                            store
                                                                .dispatch(checkStoreName(this.state.license.StoreName))
                                                                .then((response) => {
                                                                    if (response) {
                                                                        if (this.state.license.companyKey) {
                                                                            this.setState({ loading: true });
                                                                            store
                                                                                .dispatch(
                                                                                    checkCompanyKey(
                                                                                        this.state.license.companyKey
                                                                                    )
                                                                                )
                                                                                .then((result) => {
                                                                                    if (result.licenseFound) {
                                                                                        if (result.isPaidFor)
                                                                                            this.handleDeployForPaidLicense();
                                                                                        else {
                                                                                            this.handleNextStep(e);
                                                                                            this.setState({
                                                                                                loading: false,
                                                                                            });
                                                                                        }
                                                                                    } else {
                                                                                        this.setState({
                                                                                            showErrorMessage: true,
                                                                                            loading: false,
                                                                                        });
                                                                                    }
                                                                                });
                                                                        } else {
                                                                            this.handleNextStep(e);
                                                                            this.setState({
                                                                                loading: false,
                                                                            });
                                                                        }
                                                                    } else {
                                                                        this.setState({ storeNameUnavailable: true });
                                                                    }
                                                                });
                                                        }}
                                                        //useSubmitBehavior={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                                {this.state.step === 5 && (
                                    <ModuleSelection
                                        handleNextStep={this.handleNextStep}
                                        handlePreviousStep={this.handlePreviousStep}
                                        updateModules={(modules) =>
                                            this.setState({
                                                license: { ...this.state.license, ModulesBought: modules },
                                            })
                                        }
                                    />
                                )}
                                {this.state.step === 6 && (
                                    <React.Fragment>
                                        <h4>Zahlungsabwicklung</h4>
                                        <div className={"dx-field"}>
                                            <div className={"dx-field-value"}>
                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                        style={{ height: 40, marginRight: 10 }}
                                                        disabled={this.state.step === 1}
                                                        text="Zurück"
                                                        onClick={this.handlePreviousStep}
                                                    />
                                                    <Button
                                                        type="default"
                                                        text="Weiter zur Zahlung"
                                                        onClick={() =>
                                                            store
                                                                .dispatch(getPaymentLink(this.state.license))
                                                                .then((response) => {
                                                                    window.Novalnet.setParam("nn_it", "inline");
                                                                    window.Novalnet.setParam(
                                                                        "txn_secret",
                                                                        response.transaction.txn_secret
                                                                    );
                                                                    this.togglePaymentForm();
                                                                    window.Novalnet.render("novalnet_payment_form");
                                                                    localStorage.setItem(
                                                                        "license",
                                                                        JSON.stringify(this.state.license)
                                                                    );
                                                                    //window.open(response.result.redirect_url);
                                                                })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>{" "}
                                    </React.Fragment>
                                )}
                            </form>
                        )}
                        {this.state.paymentConfirmed && !this.state.paymentFailed && (
                            <Confirmation payedByParent={this.state.payedByParent} />
                        )}
                        {this.state.showPaymentForm && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{ width: 1300, position: "absolute", top: 50 }}
                                    id="novalnet_payment_form"
                                />
                            </div>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default withRouter(RegistrationForm);
