import { Button, Popup, TextBox, ValidationGroup, Validator } from "devextreme-react";
import { Button as TextBoxButton } from "devextreme-react/text-box";
import { RequiredRule } from "devextreme-react/validator";
import React from "react";
import { connect } from "react-redux";
import { register, SET_REGISTRATION_STATUS } from "../../../../actions/identityActions";
import { userActions } from "../../../../actions/userActions";
import store from "../../../../store";
import notify from "devextreme/ui/notify";
import { getToast } from "../../../../helpers/requestHelpers";
import { LoadPanel } from "devextreme-react/load-panel";
import eyeIcon from "../../../../img/eye-solid.svg";
import eyeSlashIcon from "../../../../img/eye-slash-solid.svg";

class PopupAddUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                username: "",
                password: generatePassword(),
                isLoading: false,
                showPassword: false,
            },
            errors: [],
            userLoggedIn: this.props.identity.user,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value,
            },
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        const { user, userLoggedIn } = this.state;
        if (user.username && user.password) {
            store.dispatch(register(userLoggedIn, user, this.props.resellerId)).then((response) => {
                if (!response.Succeeded) {
                    notify(getToast("Anlegen des Benutzers fehlgeschlagen.", "error"));
                    this.setState({
                        errors: response.errors,
                        isLoading: false,
                    });
                } else {
                    notify(getToast("Benutzer anlegen erfolgreich.", "success"));
                    this.props.reloadUsers();
                    this.props.hide();
                    this.setState({
                        errors: [],
                        isLoading: false,
                    });
                }
            });
        }
    }

    render() {
        return (
            <Popup                 
                visible={this.props.showAddPopup}
                closeOnOutsideClick={true}
                onHiding={this.props.hide}
                title={"Neuen Benutzer anlegen"}
            >
                <LoadPanel visible={this.state.isLoading} container="#dishes" />
                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <ValidationGroup>
                        <div className={"dx-field"}>
                            <TextBox
                                name="username"
                                mode="email"
                                placeholder={"E-Mail"}
                                onValueChanged={this.handleChange}
                            >
                                <Validator>
                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                </Validator>
                            </TextBox>
                        </div>

                        <div className={"dx-field"}>
                            <TextBox
                                name="password"
                                mode={this.state.showPassword ? "text" : "password"}
                                placeholder={"Passwort"}
                                onValueChanged={this.handleChange}
                            >
                                <TextBoxButton
                                    name="showPassword"
                                    style={{ marginRight: 5 }}
                                    options={{
                                        location: "after",
                                        type: "default",
                                        color: "#fff",
                                        icon: this.state.showPassword ? eyeSlashIcon : eyeIcon,
                                        onClick: () => {
                                            this.setState({ showPassword: !this.state.showPassword });
                                        },
                                    }}
                                ></TextBoxButton>
                                <Validator>
                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={"dx-field"}>
                            <TextBox
                                name="firstName"
                                mode="text"
                                placeholder={"Vorname"}
                                onValueChanged={this.handleChange}
                            >
                                <Validator>
                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={"dx-field"}>
                            <TextBox
                                name="lastName"
                                mode="text"
                                placeholder={"Nachname"}
                                onValueChanged={this.handleChange}
                            >
                                <Validator>
                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={"dx-field"}>
                            <TextBox
                                name="phone"
                                mode="text"
                                placeholder={"Telefonnummer"}
                                onValueChanged={this.handleChange}
                            >
                                <Validator>
                                    <RequiredRule message={"Dieses Feld wird benötigt"} />
                                </Validator>
                            </TextBox>
                        </div>
                        {!this.state.errors ||
                            (this.state.errors.length === 0 && (
                                <div style={{ fontSize: 11, marginBottom: 5 }}>
                                    <span>{"Passwords must be at least 6 characters." + "."}</span>
                                    <br />
                                    <span>
                                        {"Passwords must have at least one non alphanumeric character." + "."}
                                    </span>
                                    <br />
                                    <span>{"Passwords must have at least one digit ('0'-'9')." + "."}</span>
                                    <br />
                                    <span>{"Passwords must have at least one uppercase ('A'-'Z')." + "."}</span>
                                    <br />
                                    <span>{"Passwords must have at least one lowercase ('a'-'z')." + "."}</span>{" "}
                                </div>
                            ))}
                        {this.state.errors &&
                            this.state.errors.length > 0 &&
                            this.state.errors.map((error, index) => {
                                return (
                                    <div style={{ color: "red" }} key={index}>
                                        {error.description}
                                    </div>
                                );
                            })}
                        <div style={{ marginTop: 5 }} className={"dx-field"}>
                            <div className={"dx-field-value"}>
                                <Button text={"Speichern"} type="default" useSubmitBehavior={true} />
                            </div>
                        </div>
                    </ValidationGroup>
                </form>
            </Popup>
        );
    }
}

function generatePassword() {
    var length = 32,
        charsetLower = "abcdefghijklmnopqrstuvwxyz",
        charsetUpper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        charsetNumeric = "0123456789",
        charsetAlphaNumeric = "!?-_()",
        retVal = "";
    for (var i = 0, n = charsetLower.length; i < length / 4; ++i) {
        retVal += charsetLower.charAt(Math.floor(Math.random() * n));
    }
    for (i = 0, n = charsetUpper.length; i < length / 4; ++i) {
        retVal += charsetUpper.charAt(Math.floor(Math.random() * n));
    }
    for (i = 0, n = charsetNumeric.length; i < length / 4; ++i) {
        retVal += charsetNumeric.charAt(Math.floor(Math.random() * n));
    }
    for (i = 0, n = charsetAlphaNumeric.length; i < length / 4; ++i) {
        retVal += charsetAlphaNumeric.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(PopupAddUser);
