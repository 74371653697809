import React, { Component } from "react";
import store from "../../../store";
import DataGrid, { Column, Editing, Paging, Texts, SearchPanel, Lookup } from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import "./ServerUrls.scss";
import { getServerUrls, updateServerUrl, deleteServerUrl, insertServerUrl } from "../../../actions/serverUrlActions";
import { getWebShopTypes } from "../../../actions/webShopActions";

class ServerUrls extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverUrls: [],
            webShopTypes: [],
        };

        this.handleInsert = this.handleInsert.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleInit = this.handleInit.bind(this);
    }

    componentDidMount() {
        store.dispatch(getWebShopTypes()).then((json) => {
            this.setState({ webShopTypes: json });
        });
    }

    render() {
        return (
            <div className="audit">
                <h1>Server-Urls</h1>
                <DataGrid
                    dataSource={this.state.serverUrls}
                    keyExpr="serverUrlId"
                    showBorders={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    onInitialized={this.handleInit}
                    onRowRemoved={this.handleRemove}
                    onRowUpdated={this.handleUpdate}
                    onRowInserting={this.handleInsert}
                    columnAutoWidth={true}
                >
                    <SearchPanel visible={true} width={240} placeholder="Suche..." />
                    <Paging enabled={false} />
                    <Editing mode={"cell"} allowUpdating={true} allowAdding={true} allowDeleting={true}>
                        <Texts
                            deleteRow="Löschen"
                            confirmDeleteMessage="Soll dieser Eintrag wirklich gelöscht werden?"
                        />
                    </Editing>
                    <Column dataField="serverUrlId" caption={"ID"} allowEditing={false} width="60px" />
                    <Column dataField="webShopType" caption={"Webshop-Typ"}>
                        <Lookup
                            dataSource={this.state.webShopTypes}
                            valueExpr="Key"
                            displayExpr="Value"
                            allowClearing={false}
                        />
                    </Column>
                    <Column dataField="url" caption={"Url"} />
                </DataGrid>
            </div>
        );
    }

    handleInit(e) {
        e.component.beginCustomLoading();
        store.dispatch(getServerUrls()).then((json) => {
            this.setState({ serverUrls: json });
            e.component.endCustomLoading();
        });
    }

    handleUpdate(e) {
        e.component.beginCustomLoading();
        store.dispatch(updateServerUrl(e.data)).then((response) => {
            this.handleInit(e);
            e.component.endCustomLoading();
        });
    }

    handleRemove(e) {
        e.component.beginCustomLoading();
        store.dispatch(deleteServerUrl(e.data)).then((response) => {
            this.handleInit(e);
            e.component.endCustomLoading();
        });
    }

    handleInsert(e) {
        e.component.beginCustomLoading();
        store.dispatch(insertServerUrl(e.data)).then((response) => {
            this.handleInit(e);
            e.component.endCustomLoading();
        });
    }
}

export default ServerUrls;
