import React, { Component } from "react";
import store from "../../../../store";
import DataGrid, { Column, Editing, Paging, SearchPanel, Texts } from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import "./Users.scss";
import {
    deleteUser,
   getUsersByResellerId
} from "../../../../actions/userActions";
import { connect } from "react-redux";
import PopupEditUser from "./PopupEditUser";
import { Button } from "devextreme-react";
import PopupAddUser from "./PopupAddUser";
import notify from "devextreme/ui/notify";
import { getToast } from "../../../../helpers/requestHelpers";
import Popup, { ToolbarItem } from "devextreme-react/popup";

class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],            
            resellerId: 0,
            selectedUser: undefined,
            showEditPopup: false,
            showAddPopup: false
        };

      this.handleUsersInit = this.handleUsersInit.bind(this);      
      this.unselectUser = this.unselectUser.bind(this);      
      this.reloadUsers = this.reloadUsers.bind(this);      
      this.toggleAddPopup = this.toggleAddPopup.bind(this);      
      this.handleDeleteUser = this.handleDeleteUser.bind(this);      
      this.toggleDeletePopup = this.toggleDeletePopup.bind(this);      
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({
            resellerId: id,
        });       
    }

    reloadUsers() {
        const { id } = this.props.match.params;
        store.dispatch(getUsersByResellerId(id)).then((json) => {
            this.setState({ users: json });
        });
    }

    toggleAddPopup () {
        this.setState({ showAddPopup: !this.state.showAddPopup });
    }

    toggleDeletePopup () {
        this.setState({ showDeletePopup: !this.state.showDeletePopup });
    }

    render() {
        return (
            <div className="users">
                <div>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h1>Benutzer verwalten</h1>
                        <Button
                            onClick={() => {
                                this.toggleAddPopup();
                            }}
                            height={40}
                            type="default"
                            text="Benutzer hinzufügen"
                        />
                    </div>
                    <DataGrid
                        dataSource={this.state.users}
                        keyExpr={"Id"}
                        showBorders={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        onInitialized={this.handleUsersInit}                       
                    >
                        <SearchPanel visible={true} width={240} placeholder="Suche..." />
                        <Paging enabled={false} />                   
                        <Column dataField="UserName" caption={"Benutzername"} allowEditing={false} />
                        <Column dataField="FirstName" caption={"Vorname"} />
                        <Column dataField="LastName" caption={"Nachname"} />      
                        <Column
                            dataField="Id"
                            caption=""
                            width="90"
                            cellRender={(e) => {
                                return (
                                    <React.Fragment>
                                        <Button
                                            onClick={() => {
                                                this.setState({ selectedUser: e.data, showEditPopup: true, });
                                            }}
                                            stylingMode="text"
                                            type="default"
                                            icon="edit"
                                            Id={e.value}
                                        />
                                        <Button
                                            stylingMode="text"
                                            type="default"
                                            icon="trash"
                                            Id={e.value}
                                            onClick={() => {
                                                this.setState({ selectedUser: e.data, showDeletePopup: true, });
                                            }}
                                        />
                                    </React.Fragment>
                                );
                            }}
                        />                  
                    </DataGrid>
                    <PopupEditUser
                        showEditPopup={this.state.showEditPopup}
                        userData={this.state.selectedUser}
                        hide={this.unselectUser}   
                        reloadUsers={this.reloadUsers}                 
                    />
                    <PopupAddUser
                        showAddPopup={this.state.showAddPopup}
                        hide={this.toggleAddPopup}  
                        reloadUsers={this.reloadUsers}    
                        resellerId={this.state.resellerId}           
                    />
                    <Popup
                        title="Benutzer löschen"
                        visible={this.state.showDeletePopup}
                        onHiding={this.toggleDeletePopup}
                        hideOnOutsideClick={true}
                        width={300}
                        height={200}
                    >
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="before"
                            options={{text: "Abbrechen", onClick: this.toggleDeletePopup}}
                        />
                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"
                            options={{text: "Löschen", onClick:() => this.handleDeleteUser(this.state.selectedUser)}}
                        />
                        <p>Soll der Benutzer {this.state.selectedUser ? this.state.selectedUser.FirstName : ""} {this.state.selectedUser  ? this.state.selectedUser.LastName : ""} wirklich gelöscht werden?</p>
                    </Popup>
                </div>             
            </div>
        );
    }

    unselectUser() {
        this.setState({
            showEditPopup: false,
        });
    }

    prepareUserData(userData) {
        if (userData.UserRoles.length > 0) {
            var roles = [];
            if (userData.UserRoles[0].Role) {
                roles = userData.UserRoles.map(userRole => {
                    return userRole.Role.Id;
                });
            } else {
                roles = userData.UserRoles;
            }

            userData.UserRoles = roles;
        }

        return userData;
    }

    handleUsersInit(e) {
        const { id } = this.props.match.params;
        e.component.beginCustomLoading();
        store.dispatch(getUsersByResellerId(id)).then((json) => {
            this.setState({ users: json });
            e.component.endCustomLoading();
        });
    }      

    handleDeleteUser(e) {
        const { id } = this.props.match.params;
        if (e.Id === this.props.identity.user.Id) {
            notify(getToast("Löschen nicht möglich. Sie können sich nicht selbst löschen", "error"));
            return;
        }
        store.dispatch(deleteUser(e.Id)).then((json) => {
            this.setState({
                showDeletePopup: false,
            })
            store.dispatch(getUsersByResellerId(id)).then((json) => {
                this.setState({ users: json });
            });
        });
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(Users);
