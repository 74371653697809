import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRouteAdmin = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            let u = JSON.parse(localStorage.getItem("user"));
            return u &&
                u.Id &&
                u.Username &&
                u.Token
                ? ( //user existiert
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{ pathname: "/login", state: { from: props.location } }}
                    />
                );
        }}
    />
);
