import { DataGrid } from "devextreme-react";
import { Column, Editing, Paging, Texts } from "devextreme-react/data-grid";
import { Resource } from "devextreme-react/scheduler";
import React, { Component } from "react";
import { deleteModule, getAllModules, insertModule, updateModule } from "../../../actions/moduleActions";
import store from "../../../store";

export default class modules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: undefined,
        };
        this.handleInsert = this.handleInsert.bind(this);
        this.handleInit = this.handleInit.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleInit(e) {
        e.component.beginCustomLoading();
        store.dispatch(getAllModules()).then((json) => {
            this.setState({ modules: json });
            e.component.endCustomLoading();
        });
    }

    handleUpdate(e) {
        e.component.beginCustomLoading();
        store.dispatch(updateModule(e.data)).then((response) => {
            this.handleInit(e);
            e.component.endCustomLoading();
        });
    }

    handleRemove(e) {
        e.component.beginCustomLoading();
        store.dispatch(deleteModule(e.data)).then((response) => {
            this.handleInit(e);
            e.component.endCustomLoading();
        });
    }

    handleInsert(e) {
        e.component.beginCustomLoading();
        store.dispatch(insertModule(e.data)).then((response) => {
            e.component.endCustomLoading();
        });
    }

    render() {
        return (
            <React.Fragment>
                <h1 style={{ marginLeft: 20 }}>Module</h1>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "Center",
                        flexDirection: "column",
                        height: "auto",
                    }}
                >
                    <div style={{ width: 800 }}>
                        <DataGrid
                            dataSource={this.state.modules}
                            keyExpr="moduleId"
                            showBorders={true}
                            showRowLines={true}
                            rowAlternationEnabled={true}
                            onInitialized={this.handleInit}                            
                            onRowUpdated={this.handleUpdate}                           
                            columnAutoWidth={true}
                        >
                            <Paging enabled={false} />
                            <Editing mode="cell" allowUpdating={true}>
                                <Texts
                                    deleteRow="Löschen"
                                    confirmDeleteMessage="Soll dieser Eintrag wirklich gelöscht werden?"
                                />
                            </Editing>
                            <Column dataField="name" caption={"Name"} width="160px" />
                            <Column dataField="price" caption={"Preis"} width="160px" />                        
                        </DataGrid>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
