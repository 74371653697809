import React, { Component } from "react";
import Page from "./page/Page";
import "./Content.scss";

import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Navigation from "./navigation/Navigation";

class Content extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
    };
    render() {
        return (
            <React.Fragment>
                <div id="main" className={this.props.location.pathname === "/" ? "home" : ""}>
                    {this.props.identity && this.props.identity.user && this.props.identity.user.Username && (
                        <Navigation />
                    )}
                    <div id="content" className="content">
                        <Page />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { navigation, identity } = state;
    return {
        navigation,
        identity,
    };
}

export default connect(mapStateToProps)(withRouter(Content));
