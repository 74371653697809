import { Button, TextBox, ValidationGroup, Validator } from "devextreme-react";
import { RequiredRule } from "devextreme-react/validator";
import React from "react";
import { connect } from "react-redux";
import { login } from "../../../../actions/identityActions";
import { history } from "../../../../helpers/history";
import store from "../../../../store";
import "./LoginForm.scss";
import notify from "devextreme/ui/notify";
import { getToast } from "../../../../helpers/requestHelpers";

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.redirectLoggedInUser = this.redirectLoggedInUser.bind(this);

        this.handleEmailChange = e => {
            this.setState({
                username: e.value
            });
        };

        this.handlePasswordChange = e => {
            this.setState({
                password: e.value
            });
        };
    }

    componentDidMount() {
        this.redirectLoggedInUser();
    }

    componentDidUpdate() {
        this.redirectLoggedInUser();
    }

    redirectLoggedInUser() {
        if (this.props.identity.user.Token) {
            history.push("/");
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        const { username, password } = this.state;
        if (username && password) {
            store.dispatch(login(username, password)).then(response => {
                if (response.user.status === 401) {
                    notify(getToast(response.user.message, "error"));
                }
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    <ValidationGroup>
                        <div className={"dx-field"}>
                            <TextBox
                                name="username"
                                mode="email"
                                placeholder="E-Mail-Adresse"
                                required
                                onValueChanged={this.handleEmailChange}
                            >
                                <Validator>
                                    <RequiredRule
                                        message={"Dieses Feld wird benötigt"}
                                    />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={"dx-field"}>
                            <TextBox
                                name="password"
                                mode="password"
                                placeholder="Passwort"
                                required
                                onValueChanged={this.handlePasswordChange}
                            >
                                <Validator>
                                    <RequiredRule
                                        message={"Dieses Feld wird benötigt"}
                                    />
                                </Validator>
                            </TextBox>
                        </div>

                        <div className={"dx-field"}>
                            <div className={"dx-field-value"}>
                                <Button
                                    text="Login"
                                    type="default"
                                    useSubmitBehavior={true}
                                />
                            </div>
                        </div>
                    </ValidationGroup>
                </form>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity
    };
}

export default connect(mapStateToProps)(LoginForm);
