import { Grid, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React, { Fragment } from "react";
import { DateBox, Button } from "devextreme-react";
import SelectBox from "devextreme-react/select-box";

class AddModulePopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            license: this.props.license,
            selectedStartDate: new Date(),
            selectedModule: undefined,
            error: false
        };

        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onAddModule = this.onAddModule.bind(this);
        this.moduleSelected = this.moduleSelected.bind(this);
    }

    onAddModule(e) {
        if (this.state.selectedModule) {
            this.props.onAddModule(
                this.state.selectedStartDate,
                this.state.selectedModule
            );
            this.setState({
                selectedModule: undefined,
                selectedStartDate: new Date(),
                error: false
            })
        }
        else {
            this.setState({
                error: true
            })
        }
    }

    moduleSelected(e) {
        this.setState({
            selectedModule: e.selectedItem,
        });
    }

    onStartDateChange(e) {
        this.setState({
            selectedStartDate: new Date(e.value),
        });
    }

    render() {
        return (
            <div>
                <Dialog
                    aria-labelledby="customized-dialog-title" 
                    open={this.props.showAddModulePopUp}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.props.toggleAddModulePopup}>
                        Modul Hinzufügen
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <h3>Modul auswählen</h3>
                            <SelectBox
                                dataSource={this.props.modules}
                                displayExpr="displayName"
                                valueExpr={"moduleId"}
                                placeholder="Modul wählen"
                                showClearButton={true}
                                onSelectionChanged={(e) => {
                                    this.moduleSelected(e);
                                }}
                            />
                            {this.state.error && <p style={{color: "red", margin: 0}}>Bitte Modul auswählen</p>}
                            <h3 style={{marginTop: 10}}>Startdatum</h3>
                            <DateBox
                                value={this.state.selectedStartDate}
                                type="date"
                                onValueChanged={(e) => {
                                    this.onStartDateChange(e);
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button height={34} onClick={this.props.toggleAddModulePopup} color="primary">
                            Abbrechen
                        </Button>
                        <Button height={34} onClick={this.onAddModule}>Hinzufügen</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default AddModulePopUp;
