import { Button, Popup, TextBox } from "devextreme-react";
import React, { Component } from "react";
import { generateApiKeyAndSecret, getLicenseById } from "../../../../actions/licensesActions";
import store from "../../../../store";

export default class ApiKey extends Component {
    constructor(props) {
        super(props);

        this.state = {
            license: undefined,
            apiKey: "",
            apiSecret: "",
        };

        this.generateApiKeyAndSecret = this.generateApiKeyAndSecret.bind(this);
    }

    componentDidMount() {
        store.dispatch(getLicenseById(this.props.licenseId)).then((result) => this.setState({ license: result }));
    }

    generateApiKeyAndSecret() {
        store.dispatch(generateApiKeyAndSecret(this.props.licenseId)).then((result) => {
            this.setState({ apiKey: result.apiKey, apiSecret: result.clientSecret });
            store.dispatch(getLicenseById(this.props.licenseId)).then((result) => this.setState({ license: result }));
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.license && (
                    <div
                        style={{
                            width: 500,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "auto",
                            marginTop: 50,
                        }}
                    >
                        <TextBox
                            value={
                                this.state.license.apiKey
                                    ? this.state.license.apiKey
                                    : "Für diese Lizenz wurde noch kein API-Key erstellt."
                            }
                            disabled={true}
                            style={{ width: 400, marginBottom: 20 }}
                        />
                        <Button type="default" onClick={this.generateApiKeyAndSecret}>
                            API-Key und Secret generieren
                        </Button>
                    </div>
                )}
                <Popup visible={this.state.apiSecret} onHiding={() => this.setState({ apiSecret: "" })}>
                    <p>
                        Notiere die folgenden Angaben, sie können nicht mehr abgerufen werden nachdem dieses fenster
                        geschlossen wurde !
                    </p>
                    <p>Api key : {this.state.apiKey}</p>
                    <p>Client Secret : {this.state.apiSecret}</p>
                </Popup>
            </React.Fragment>
        );
    }
}
