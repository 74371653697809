import React, { Component } from "react";
import { Route } from "react-router-dom";
import { PrivateRouteAdmin } from "../../../core/privateRoute/PrivateRouteAdmin";
import Audit from "../../../pages/audit/Audit";
import Orchestration from "../../../pages/orchestration/Orchestration";
import Dashboard from "../../../pages/dashboard/Dashboard";
import Resellers from "../../../pages/resellers/Resellers";
import Licenses from "../../../pages/licenses/Licenses";
import Login from "../../../pages/login/Login";
import ServerUrls from "../../../pages/serverUrls/ServerUrls";
import UsageLogs from "../../../pages/usageLogs/UsageLogs";
import Users from "../../../pages/resellers/users/Users";
import "./Page.scss";
import Registration from "../../../pages/registration/Registration";
import modules from "../../../pages/modules/Modules";
import LicenseTabManagement from "../../../pages/licenses/LicenseTabManagement";
import Webshop from "../../../pages/licenses/webShops/WebShop";

class Page extends Component {
    render() {
        return (
            <div className="pages">
                {/* <PrivateRouteAdmin exact path="/" component={Home} /> */}
                {/* <PrivateRouteAdmin exact path="/dashboard" component={Dashboard} /> */}
                <PrivateRouteAdmin exact path="/" component={Dashboard} />
                {/*FIXME change to PrivateRoute*/}
                <Route path="/login" component={Login} />
                <Route path="/registration/" component={Registration} />
                <PrivateRouteAdmin exact path="/lizenzen" component={Licenses} />
                <PrivateRouteAdmin exact path="/webShop/:id" component={Webshop} />
                <PrivateRouteAdmin exact path="/resellers" component={Resellers} />
                <PrivateRouteAdmin exact path="/audit" component={Audit} />
                <PrivateRouteAdmin exact path="/usageLogs" component={UsageLogs} />
                <PrivateRouteAdmin exact path="/serverUrls" component={ServerUrls} />         
                <PrivateRouteAdmin exact path="/lizenzen/:id" component={LicenseTabManagement} />
                <PrivateRouteAdmin exact path="/resellers/:id" component={Users} />
                <PrivateRouteAdmin exact path="/orchestration" component={Orchestration} />
                <PrivateRouteAdmin exact path="/modules" component={modules} />
            </div>
        );
    }
}

export default Page;
