import { DataGrid, Button } from "devextreme-react";
import { SearchPanel, Paging, Editing, Column, Texts } from "devextreme-react/data-grid";

import React from "react";
import { getLicenseById, updateLicense } from "../../../../actions/licensesActions";
import { getAllModules } from "../../../../actions/moduleActions";
import store from "../../../../store";
import AddModulePopUp from "./addModule/AddModulePopUp";
import EditModulePopUp from "./editModule/EditModulePopUp";
import "./Modules.scss";

class Modules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseId: this.props.licenseId,
            license: undefined,
            modules: [],
            selectedModuleInstance: undefined,
            showAddModulePopUp: false,
            showEditModulePopUp: false,
        };

        this.toggleAddModulePopup = this.toggleAddModulePopup.bind(this);
        this.onAddModule = this.onAddModule.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.toggleEditModulePopUp = this.toggleEditModulePopUp.bind(this);
        this.openEditModulePopUp = this.openEditModulePopUp.bind(this);
        this.editButton = this.editButton.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.refreshModules = this.refreshModules.bind(this);
        this.updateAndRefreshLicense = this.updateAndRefreshLicense.bind(this);
    }

    componentDidMount() {      
        store.dispatch(getLicenseById(this.state.licenseId)).then((result) => {
            this.setState({
                license: result
            }, () => this.refreshModules(result.LicenseModules));
        });
    }

    updateAndRefreshLicense(updatedLicense){
        store.dispatch(updateLicense(updatedLicense)).then((result) => {
            store.dispatch(getLicenseById(this.state.licenseId)).then((license) => {
                this.setState({
                    license: license
                }, () => this.refreshModules(license.LicenseModules));
            });
        });
    }

    refreshModules(licenseModules){
        var today = new Date();
        store.dispatch(getAllModules()).then((response) => {
            var tempModules = [];
            response.forEach((module) => {
                if (
                    !(licenseModules.filter((e) => e.moduleId === module.moduleId).length > 0) ||
                    (new Date(licenseModules.filter((e) => e.moduleId === module.moduleId)[0].expiryDate) <
                        today &&
                        licenseModules.filter((e) => e.moduleId === module.moduleId)[0].expiryDate !== null)
                ) {
                    tempModules.push(module);
                }
            });
            this.setState({                
                modules: tempModules,
            });
        });
    }

    toggleAddModulePopup() {
        this.setState({
            showAddModulePopUp: !this.state.showAddModulePopUp,
        });
    }

    onAddModule(selectedStartDate, selectedModule) {
        this.toggleAddModulePopup();
        let tempLicense = this.state.license;        
        if (this.state.license.LicenseModules.filter((e) => e.moduleId === selectedModule.moduleId).length > 0) {
            let newLicenseModules = this.state.license.LicenseModules;
            newLicenseModules.forEach((licenseModule) => {
                if (licenseModule.moduleId === selectedModule.moduleId) {
                    licenseModule.startDate = selectedStartDate;
                    licenseModule.expiryDate = null;
                }
            });

            tempLicense.LicenseModules = newLicenseModules;
        } else {
            let newLicenseModules = {
                Module: selectedModule,
                startDate: selectedStartDate,
                moduleId: selectedModule.moduleId,
                licenseId: this.state.licenseId,
            };

            tempLicense.LicenseModules = tempLicense.LicenseModules.concat(newLicenseModules);
        }

        this.updateAndRefreshLicense(tempLicense);
    }

    saveChanges(data) {
        this.toggleEditModulePopUp();
        const newLicenseModules = this.state.license.LicenseModules.map((LicenseModule) => {
            if (LicenseModule.moduleId === data.moduleId) {
                if (data.expiryDate !== undefined) {
                    LicenseModule.expiryDate = data.expiryDate;
                }
                if (data.startDate !== undefined) {
                    LicenseModule.startDate = data.startDate;
                }
            }
            return LicenseModule;
        });

        let tempLicense = this.state.license;
        tempLicense.LicenseModules = newLicenseModules;

       this.updateAndRefreshLicense(tempLicense);
    }

    openEditModulePopUp(props) {
        this.setState({
            selectedModuleInstance: props,
        }, () => this.toggleEditModulePopUp());        
    }

    toggleEditModulePopUp() {
        this.setState({
            showEditModulePopUp: !this.state.showEditModulePopUp,
        });
    }

    editButton(props) {
        return (
            <div>
                <Button height={24} onClick={() => this.openEditModulePopUp(props.data)}>Bearbeiten</Button>
            </div>
        );
    }

    onDateChange(e) {
        const newLicenseModules = this.state.license.LicenseModules.map((LicenseModules) => {
            if (LicenseModules.moduleId === e.oldData.moduleId) {
                if (e.newData.expiryDate) {
                    return { ...LicenseModules, expiryDate: e.newData.expiryDate };
                } else {
                    return { ...LicenseModules, startDate: e.newData.startDate };
                }
            } else {
                return LicenseModules;
            }
        });

        let tempLicense = this.state.license;
        tempLicense.LicenseModules = newLicenseModules;

        store.dispatch(updateLicense(tempLicense)).then((result) => {
            store.dispatch(getLicenseById(this.state.licenseId)).then((response) => {
                this.setState({
                    license: response,
                });
            });
        });
    }

    render() {
        return (
            <div>
                <AddModulePopUp
                    showAddModulePopUp={this.state.showAddModulePopUp}
                    toggleAddModulePopup={this.toggleAddModulePopup}
                    onAddModule={this.onAddModule}
                    modules={this.state.modules}
                    license={this.state.license}
                />
                <EditModulePopUp
                    showEditModulePopUp={this.state.showEditModulePopUp}
                    toggleEditModulePopUp={this.toggleEditModulePopUp}
                    license={this.state.license}
                    selectedLicenseModule={this.state.selectedModuleInstance}
                    saveChanges={this.saveChanges}
                />
                <div className="modules">
                    <div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <h1>Aktive Module</h1>
                            <Button style={{marginLeft: 10}} id="addButton" onClick={this.toggleAddModulePopup} text="Neues Modul hinzufügen" />
                        </div>   
                        {this.state.license && (
                            <DataGrid
                                dataSource={this.state.license.LicenseModules.map((LicenseModule) => {
                                    let today = new Date();
                                    if (
                                        new Date(LicenseModule.expiryDate) > today.getTime() ||
                                        !LicenseModule.expiryDate
                                    ) {
                                        return LicenseModule;
                                    }
                                })}
                                keyExpr="moduleId"
                                showBorders={true}
                                showRowLines={true}
                                rowAlternationEnabled={true}
                                onRowUpdating={this.onDateChange}
                            >
                                <SearchPanel visible={true} width={240} placeholder="Suche..." />
                                <Paging enabled={false} />
                                <Editing mode={"cell"} allowUpdating={true} allowDeleting={false}>
                                    <Texts
                                        editRow="Bearbeiten"
                                        cancelRowChanges="Abbrechen"
                                        saveRowChanges="Speichern"
                                    />
                                </Editing>

                                <Column dataField="Module.name" caption={"Modul"} allowEditing={false} />
                                <Column
                                    dataField="startDate"
                                    dataType={"date"}
                                    caption={"Beginn"}
                                    allowEditing={false}
                                    alignment={"right"}
                                />
                                <Column
                                    dataField="expiryDate"
                                    dataType={"date"}
                                    caption={"Ende"}
                                    allowEditing={false}
                                    alignment={"right"}
                                />
                                <Column
                                    dataField={"moduleInstanceId"}
                                    caption={""}
                                    cellRender={(e) => this.editButton(e)}
                                    allowEditing={false}
                                    alignment={"center"}
                                    width={"150px"}
                                />
                            </DataGrid>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Modules;
