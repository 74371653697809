let user = JSON.parse(localStorage.getItem("user"));
const initialState = {
    user: user ? user : {},
    registrationStatus: {
        Errors: [],
        Succeeded: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_REGISTRATION_STATUS":
            return Object.assign({}, state, {
                registrationStatus: action.registrationStatus
            });
        case "SET_USER":
            localStorage.setItem("user", JSON.stringify(action.user));
            return Object.assign({}, state, {
                user: action.user
            });
        case "LOGOUT_USER":
            localStorage.removeItem("user");
            return Object.assign({}, state, {
                user: {}
            });
        default:
            return state;
    }
};
