import React, { Component } from "react";
import { Button } from "devextreme-react";
import { getContainerInstances } from "../../../actions/containerActions";
import store from "../../../store";
import ContainerInstance from "./ContainerInstance";

export default class Orchestration extends Component {
    constructor() {
        super();

        this.state = {
            containerInstances: [],
            showEditPopup: false,
            showAddPopup: false,
        };

        this.refreshDatagrid = this.refreshDatagrid.bind(this);
    }

    componentDidMount() {
        store.dispatch(getContainerInstances()).then((result) => this.setState({
            containerInstances: result
        }))
    }

    refreshDatagrid() {
        store.dispatch(getContainerInstances()).then((result) => this.setState({
            containerInstances: result
        }))
    }

    render() {
        return (
            <div style={{ maxWidth: "1000px", margin: "auto", marginTop: "10px" }}>
                <Button style={{ marginBottom: 10 }} icon="refresh" onClick={this.refreshDatagrid} />
                <div style={{display: "flex", height: 40}}>
                    <div style={{width: 500, backgroundColor: "lightGrey", borderStyle: "solid", borderWidth: 1, display: "flex", justifyContent: "center", flexDirection: "column", paddingLeft: 10}}>Frontend-Url</div>
                    <div style={{width: 300, backgroundColor: "lightGrey", borderStyle: "solid", borderWidth: 1, display: "flex", justifyContent: "center", flexDirection: "column", paddingLeft: 10}}>Lizenz</div>
                    <div style={{width: 200, backgroundColor: "lightGrey", borderStyle: "solid", borderWidth: 1, display: "flex", justifyContent: "center", flexDirection: "column", paddingLeft: 10}}>Status</div>
                </div>
                <div>
                    {this.state.containerInstances.map((containerInstance) => {
                        return(
                            <ContainerInstance containerInstance={containerInstance} refreshDatagrid={this.refreshDatagrid} />
                        )
                    })}
                </div>
            </div>
        );
    }
}
