import React, { Component } from "react";
import store from "../../../store";
import DataGrid, { Column, Editing, Paging, Pager, SearchPanel } from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import { connect } from "react-redux";
import "./UsageLogs.scss";
import { getUsageLogs } from "../../../actions/usageLogActions";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

class UsageLogs extends Component {
    render() {
        return (
            <div className="audit">
                <h1>UsageLogs</h1>
                <DataGrid
                    dataSource={
                        new DataSource({
                            store: new CustomStore({
                                load: (loadOptions) => {
                                    return store.dispatch(getUsageLogs(loadOptions, this.props.identity.user.resellerId));
                                },
                            }),
                        })
                    }
                    keyExpr="UsageLogId"
                    showBorders={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={{ paging: true }}
                >
                    <SearchPanel visible={true} width={240} placeholder="Suche..." />
                    <Paging enabled={true} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={[20, 50, 100]} showInfo={true} />
                    <Editing allowUpdating={false} allowAdding={false} allowDeleting={false} />
                    <Column dataField="Name" caption={"Lizenzname"} />
                    <Column dataField="LicenseNumber" caption={"Lizenznummer"} />
                    <Column dataField="ClientName" caption={"Clientname"} />
                    <Column dataField="Sid" caption={"Sid"} />
                    <Column dataField="ClientDateTime" caption={"Client-Zeitstempel"} dataType="datetime" />
                    <Column dataField="timeStamp" caption={"Server-Zeitstempel"} dataType="datetime" />
                    <Column dataField="Version" caption={"Version"} />
                    <Column dataField="clientIp" caption={"Client-Ip"} />
                </DataGrid>
            </div>
        );
    }

    // handleInit(e) {
    //     e.component.beginCustomLoading();
    //     store.dispatch(getUsageLogs()).then((json) => {
    //         this.setState({ usageLogs: json });
    //         e.component.endCustomLoading();
    //     });
    // }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(UsageLogs);
