import React, { Component } from "react";
import store from "../../../../store";
import { Column, Editing, Paging, Texts, SearchPanel, Lookup, RequiredRule } from "devextreme-react/data-grid";
import { Button, DataGrid } from "devextreme-react";
import "devextreme-react/text-area";
import { Link } from "react-router-dom";
import "./WebShops.scss";
import {
    getWebShopsByLicenseId,
    updateWebShop,
    insertWebShop,
    getTakeAwaysByLicenseId,
    updateTakeAway,
    insertTakeAway,
    deleteTakeAway,
    getWebShopTypes,
} from "../../../../actions/webShopActions";
import { getServerUrls } from "../../../../actions/serverUrlActions";

class Webshops extends Component {
    constructor(props) {
        super(props);

        this.state = {
            webShops: [],
            takeAwayShops: [],
            webShopTypes: [],
            serverUrls: [],
            licenseId: 0,
        };

        this.handleWebShopsInsert = this.handleWebShopsInsert.bind(this);
        this.handleWebShopsUpdate = this.handleWebShopsUpdate.bind(this);
        this.handleWebShopsInit = this.handleWebShopsInit.bind(this);

        this.handleTakeAwayRemove = this.handleTakeAwayRemove.bind(this);
        this.handleTakeAwayInsert = this.handleTakeAwayInsert.bind(this);
        this.handleTakeAwayUpdate = this.handleTakeAwayUpdate.bind(this);
        this.handleTakeAwayInit = this.handleTakeAwayInit.bind(this);
        this.editShopButton = this.editShopButton.bind(this);
        this.handleEditShop = this.handleEditShop.bind(this);
    }

    componentDidMount() {
        const { licenseId } = this.props.licenseId;
        this.setState({
            licenseId: licenseId,
        });
        store.dispatch(getWebShopTypes()).then((json) => {
            this.setState({ webShopTypes: json });
        });
        store.dispatch(getServerUrls()).then((json) => {
            this.setState({ serverUrls: json });
        });
    }

    editShopButton(e) {
        console.log(e)
        return (
            <div style={{ display: "flex", justifyContent: "spaceBetween" }}>                
                <Link to={"/webshop/" + e.value}>
                    <span>Bearbeiten</span>
                </Link>
            </div>
        );
    }

    handleEditShop(e) {
        console.log(e)
    }

    render() {
        return (
            <div className="webshops">
                <div>
                    <h1>Eigene Onlineshops</h1>
                    <DataGrid
                        dataSource={this.state.webShops}
                        keyExpr="WebShopId"
                        showBorders={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        onInitialized={this.handleWebShopsInit}
                        onRowUpdated={this.handleWebShopsUpdate}
                        onRowInserting={this.handleWebShopsInsert}
                    >
                        <SearchPanel visible={true} width={240} placeholder="Suche..." />
                        <Paging enabled={false} />
                        <Editing mode={"form"} allowUpdating={false} allowAdding={true} allowDeleting={false}>
                            <Texts cancelRowChanges="Abbrechen" saveRowChanges="Speichern" />
                        </Editing>
                        <Column dataField="WebShopId" caption={"ID"} allowEditing={false} width="60px" />
                        <Column dataField="StoreName" caption={"Name"} >
                        <RequiredRule />
                        </Column>
                        <Column dataField="WebShopType" caption={"Webshop-Typ"}>
                            <Lookup
                                dataSource={this.state.webShopTypes}
                                valueExpr="Key"
                                displayExpr="Value"
                                allowClearing={false}
                            />
                             <RequiredRule />
                        </Column>
                        <Column dataField="DeployStatus" visible={false} caption={"Deploy Status"} />
                        <Column
                            dataField={"WebShopId"}
                            caption={""}
                            cellRender={this.editShopButton}
                            allowSorting={false}
                            width={"100px"}
                            alignment={"center"}
                        />
                    </DataGrid>
                </div>
                <div>
                    <h1>Lieferando Onlineshops</h1>
                    <DataGrid
                        dataSource={this.state.takeAwayShops}
                        keyExpr="TakeAwayId"
                        showBorders={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        onInitialized={this.handleTakeAwayInit}
                        onRowRemoved={this.handleTakeAwayRemove}
                        onRowUpdated={this.handleTakeAwayUpdate}
                        onRowInserting={this.handleTakeAwayInsert}
                    >
                        <SearchPanel visible={true} width={240} placeholder="Suche..." />
                        <Paging enabled={false} />
                        <Editing mode={"cell"} allowUpdating={true} allowAdding={true} allowDeleting={true}>
                            <Texts
                                deleteRow="Löschen"
                                confirmDeleteMessage="Soll dieser Eintrag wirklich gelöscht werden?"
                            />
                        </Editing>
                        <Column dataField="TakeAwayId" caption={"ID"} allowEditing={false} width="60px" />
                        <Column dataField="TakeAwayRestaurantId" caption={"Lieferando Restaurant ID"} />
                        <Column dataField="serverName" caption={"Servername"} />
                    </DataGrid>
                </div>
            </div>
        );
    }

    handleWebShopsInit(e) {
        const { licenseId } = this.props;
        e.component.beginCustomLoading();
        store.dispatch(getWebShopsByLicenseId(licenseId)).then((json) => {
            this.setState({ webShops: json });
            e.component.endCustomLoading();
        });
    }

    handleWebShopsUpdate(e) {
        e.component.beginCustomLoading();
        store.dispatch(updateWebShop(e.data)).then((response) => {
            this.handleWebShopsInit(e);
            e.component.endCustomLoading();
        });
    }

    handleWebShopsInsert(e) {
        e.component.beginCustomLoading();
        let webShop = e.data;
        webShop.LicenseId = this.props.licenseId;
        store.dispatch(insertWebShop(webShop)).then((response) => {
            this.handleWebShopsInit(e);
            e.component.endCustomLoading();
        });
    }

    handleTakeAwayInit(e) {
        const { licenseId } = this.props;
        e.component.beginCustomLoading();
        store.dispatch(getTakeAwaysByLicenseId(licenseId)).then((json) => {
            this.setState({ takeAwayShops: json });
            e.component.endCustomLoading();
        });
    }

    handleTakeAwayUpdate(e) {
        e.component.beginCustomLoading();
        store.dispatch(updateTakeAway(e.data)).then((response) => {
            this.handleTakeAwayInit(e);
            e.component.endCustomLoading();
        });
    }

    handleTakeAwayRemove(e) {
        e.component.beginCustomLoading();
        store.dispatch(deleteTakeAway(e.data)).then((response) => {
            this.handleTakeAwayInit(e);
            e.component.endCustomLoading();
        });
    }

    handleTakeAwayInsert(e) {
        e.component.beginCustomLoading();
        let webShop = e.data;
        webShop.licenseId = this.state.licenseId;
        store.dispatch(insertTakeAway(webShop)).then((response) => {
            this.handleTakeAwayInit(e);
            e.component.endCustomLoading();
        });
    }
}

export default Webshops;
