import { Button, LoadIndicator } from "devextreme-react";
import React, { Component } from "react";
import { restartContainerInstance, startContainerInstance, stopContainerInstance } from "../../../actions/containerActions";
import store from "../../../store";
import Container from "./Container";

export default class ContainerInstance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            containersVisible: true,
            isLoading: false
        };

        this.toggleContainers = this.toggleContainers.bind(this);
        this.stopContainerInstance = this.stopContainerInstance.bind(this);
        this.startContainerInstance = this.startContainerInstance.bind(this);
        this.restartContainerInstance = this.restartContainerInstance.bind(this);
    }

    toggleContainers() {
        this.setState({
            containersVisible: !this.state.containersVisible
        })
    }

    stopContainerInstance() {
        this.setState({
            isLoading: true
        })
        store.dispatch(stopContainerInstance(this.props.containerInstance[0].containerGroupName)).then((result) => {
            this.props.refreshDatagrid();
            this.setState({
                isLoading: false
            })
        });
    }

    startContainerInstance() {
        this.setState({
            isLoading: true
        })
        store.dispatch(startContainerInstance(this.props.containerInstance[0].containerGroupName)).then((result) => {
            this.props.refreshDatagrid();
            this.setState({
                isLoading: false
            })
        });
    }

    restartContainerInstance() {
        this.setState({
            isLoading: true
        })
        store.dispatch(restartContainerInstance(this.props.containerInstance[0].containerGroupName)).then((result) =>{
            this.props.refreshDatagrid();
            this.setState({
                isLoading: false
            })
        });
    }

    render() {
        return (
            <div style={{ maxWidth: "1000px",borderStyle: "solid", borderWidth: 1}}>
                <div style={{display: "flex", margin: 5}}>
                    <Button icon={this.state.containersVisible ? "chevrondown" : "chevronright"} onClick={this.toggleContainers}/>
                    <div style={{width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: 10}}>Instanz: {this.props.containerInstance[0].containerGroupName}</div>
                    {(this.props.containerInstance[0].deployStatus === 5 || this.props.containerInstance[0].deployStatus === 98) && !this.state.isLoading && 
                        <Button 
                            icon={this.props.containerInstance[0].deployStatus === 5 ? "square" : "runner"} 
                            hint={this.props.containerInstance[0].deployStatus === 5 ? "Stoppen" : "Starten"} 
                            onClick={this.props.containerInstance[0].deployStatus === 5 ? this.stopContainerInstance : this.startContainerInstance}/>
                    }
                    {this.props.containerInstance[0].deployStatus === 5 && !this.state.isLoading && <Button style={{marginLeft: 10}} icon="revert" hint="Neustarten" onClick={this.restartContainerInstance}/>}
                    {this.state.isLoading && <LoadIndicator id="medium-indicator" height={35} width={35} />}
                </div>
                {this.state.containersVisible && 
                    <div>
                        {this.props.containerInstance.map((container, index) => {
                            return(
                                <Container key={index} container={container} />
                            )
                        })}
                    </div>}
            </div>
        );
    }
}
