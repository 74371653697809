import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from "devextreme-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { LOGOUT_USER } from '../../../actions/identityActions';
import store from "../../../store";
import "./Header.scss"


class Header extends Component {
    constructor(props) {
        super(props);
        this.handleResetApp = this.handleResetApp.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleResetApp() {
        store.dispatch({ type: 'APP_RESET' });
    }

    handleLogout() {
        store.dispatch({ type: LOGOUT_USER });
    }

    render() {
        return (
            <header id="header" className={this.props.identity.user.Token ? 'logged-in' : 'not-logged-in'}>
                <div id="logo">
                    Lizenzserver
                </div>
                <div id="userMenu">
                    <Menu
                        onItemClick={(e) => { if (e.itemData.text === "Logout") this.handleLogout() }}
                        visible={this.props.identity && this.props.identity.user && this.props.identity.user.Username && true}
                        items={
                            [
                                {
                                    "text": this.props.identity.user.Username,
                                    "items": [
                                        {
                                            "text": "Logout"
                                        },
                                    ]
                                }
                            ]
                        } />
                </div>
                <div id="reloadButton">
                    <FontAwesomeIcon icon="undo" onClick={this.handleResetApp} />
                </div>
            </header>
        );
    }
}

function mapStateToProps(state) {
    const { identity, navigation } = state;
    return {
        identity,
        navigation
    };
}

export default connect(mapStateToProps)(withRouter(Header));