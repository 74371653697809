import React, { Component } from "react";
import store from "../../../store";
import { getAllSubReseller, updateResellerName, insertReseller, removeReseller, updateResellerLicenses } from "../../../actions/resellerActions";
import DataGrid, { Column, Editing, Paging, SearchPanel, Texts } from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import "./Resellers.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAllSubLicenses } from "../../../actions/licensesActions";

class Resellers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resellers: [],
            allAvailableLicenses: []
        };

        this.handleInsert = this.handleInsert.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleInit = this.handleInit.bind(this);
        this.usersButton = this.usersButton.bind(this);
        this.handleUpdateLicenses = this.handleUpdateLicenses.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    render() {
        var self = this;
        return (
            <div className="resellers">
                <h1>Reseller verwalten</h1>
                <DataGrid
                    dataSource={this.state.resellers}
                    keyExpr="resellerId"
                    showBorders={true}
                    showRowLines={true}
                    onRowInserting={this.handleInsert}
                    onRowRemoved={this.handleRemove}
                    onRowUpdated={this.handleUpdate}
                    onEditorPreparing={(e) => {
                        if (e.dataField === "licenseIds") {
                            e.editorName = "dxTagBox"
                            e.editorOptions.dataSource = this.state.allAvailableLicenses;
                            e.editorOptions.showSelectionControls = true;
                            e.editorOptions.displayExpr = "StoreName";
                            e.editorOptions.valueExpr = "LicenseId";
                            e.editorOptions.value = e.value || [];
                            e.editorOptions.onValueChanged = function (args) {
                                e.setValue(args.value);
                                self.handleUpdateLicenses(e, args, e.row.data.resellerId);
                            }
                        }
                    }}
                    rowAlternationEnabled={true}
                    onInitialized={this.handleInit}
                >
                    <SearchPanel visible={true} width={240} placeholder="Suche..." />
                    <Editing mode="cell" allowUpdating={true} allowAdding={true} allowDeleting={true} useIcons={true}>
                        <Texts
                            confirmDeleteMessage={"Möchten Sie diesen Datensatz wirklich löschen?"}
                            yes={"Ja"}
                            no={"Nein"}
                            deleteRow={"Löschen"}
                        />
                    </Editing>
                    <Paging enabled={false} />
                    <Column dataField="resellerId" caption={"ID"} allowEditing={false} width="60px" />
                    <Column dataField="resellerName" caption={"Name"} allowEditing={true} />
                    <Column 
                        dataField="licenseIds" 
                        caption={"Lizens-Name"} 
                        allowEditing={true}
                        calculateDisplayValue={(e) => 
                            {
                                var licenseNames = e.Licenses ? e.Licenses.map((license) => {return license.StoreName}).join(', ') : "";
                                return licenseNames;
                            }
                        }
                    />
                    <Column
                        dataField={"resellerId"}
                        caption={"Benutzer"}
                        cellRender={this.usersButton}
                        allowEditing={false}
                        width={120}
                    />
                </DataGrid>
            </div>
        );
    }

    usersButton(props) {
        return (
            <div>
                <Link to={`${this.props.match.url}/${props.value}`}>
                    <span>Benutzer</span>
                </Link>
            </div>
        );
    }

    async handleInit(e) {
        e.component.beginCustomLoading();
        await Promise.all([store.dispatch(getAllSubReseller(this.props.identity.user.resellerId)).then((json) => {
            this.setState({ resellers: json });
            e.component.endCustomLoading();
        }), store.dispatch(getAllSubLicenses(this.props.identity.user.resellerId)).then((response) => {
            this.setState({ allAvailableLicenses: response });
            e.component.endCustomLoading();
        })])
    }

    handleUpdate(e) {
        e.component.beginCustomLoading();
        store.dispatch(updateResellerName(e.data.resellerId, e.data.resellerName)).then((response) => {
            e.component.endCustomLoading();
        });
    }

    handleUpdateLicenses(e, licenses, resellerId) {
        if (resellerId) {
            e.component.beginCustomLoading();
            store.dispatch(updateResellerLicenses(resellerId, licenses.value)).then((result) => {
                store.dispatch(getAllSubReseller(this.props.identity.user.resellerId)).then((json) => {
                    this.setState({ resellers: json });
                    e.component.endCustomLoading();
                })
            })
        }
    }

    handleInsert(e) {
        e.component.beginCustomLoading();
        store.dispatch(insertReseller(e.data.licenseIds, e.data.resellerName)).then((response) => {
            this.handleInit(e);
            e.component.endCustomLoading();
        });
    }

    handleRemove(e) {
        e.component.beginCustomLoading();
        store.dispatch(removeReseller(e.data.resellerId)).then((response) => {
            this.handleInit(e);
            e.component.endCustomLoading();
        });
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(Resellers);
