import DataGrid, { Column, Editing, Pager, Paging, SearchPanel } from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import CustomStore from "devextreme/data/custom_store";
import React, { Component } from "react";
import { getAuditByResellerId } from "../../../actions/auditActions";
import store from "../../../store";
import { connect } from "react-redux";
import DataSource from "devextreme/data/data_source";
import "./Audit.scss";


class Audit extends Component {
    render() {
        return (
            <div className="audit">
                <h1>Audit</h1>
                <DataGrid
                    dataSource={
                        new DataSource({
                            store: new CustomStore({
                                load: (loadOptions) => {
                                    return store.dispatch(getAuditByResellerId(loadOptions, this.props.identity.user.resellerId));
                                },
                            }),
                        })
                    }
                    keyExpr="Id"
                    showBorders={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={{ paging: true }}
                >
                    <SearchPanel visible={true} width={240} placeholder="Suche..." />
                    <Paging enabled={true} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={[20, 50, 100]} showInfo={true} />
                    <Editing allowUpdating={false} allowAdding={false} allowDeleting={false} />
                    <Column dataField="name" caption={"Name"} />
                    <Column dataField="tableName" caption={"Tabelle"} />
                    <Column dataField="type" caption={"Typ"} />
                    <Column dataField="propertyName" caption={"Eigenschaft"} />
                    <Column dataField="oldValue" caption={"alter Wert"} />
                    <Column dataField="newValue" caption={"neuer Wert"} />
                    <Column dataField="timeStamp" caption={"Datum"} dataType="datetime" />
                    <Column dataField="User.UserName" caption={"Benutzer"} />
                </DataGrid>
            </div>
        );
    }

    // handleInit(e) {
    //     e.component.beginCustomLoading();
    //     store.dispatch(getAudit()).then(json => {
    //         this.setState({ audit: json });
    //         e.component.endCustomLoading();
    //     });
    // }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default connect(mapStateToProps)(Audit);
